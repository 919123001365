import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import { useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, SectionHeading } from "../core";
import { logoInCloudColor } from "../../assets/graphics";
import { IconCheck, IconCross } from "../../assets/@feathericons";

/**
 * BulletPointIcon
 */
const BulletPointIcon = ({ mode }) => {
  const _frameColor = {
    positive: "bg-statusPositive",
    negative: "bg-statusFailure_10",
  };

  const _iconColor =
    mode === "positive"
      ? "white"
      : mode === "negative"
      ? theme.colors.statusFailure
      : "white";

  const _icon =
    mode === "positive" ? (
      <IconCheck />
    ) : mode === "negative" ? (
      <IconCross />
    ) : (
      <IconCheck />
    );

  const _frameStyle = cx(
    "flex justify-center items-center w-32 h-32 rounded-full",
    _frameColor[mode]
  );

  return (
    <div className={_frameStyle}>
      {cloneElement(_icon, {
        size: 24,
        color: _iconColor,
        strokeWidth: 2,
      })}
    </div>
  );
};
BulletPointIcon.propTypes = {
  mode: PropTypes.oneOf(["positive", "negative"]),
};
BulletPointIcon.defaultProps = {
  mode: "positive",
};

/**
 * ComparisonCard
 */
const ComparisonCard = ({ title, logo, features, mode }) => {
  const _icon =
    mode === "positive" ? (
      <BulletPointIcon />
    ) : mode === "negative" ? (
      <BulletPointIcon mode="negative" />
    ) : (
      <BulletPointIcon />
    );

  const _featuresList = arr => {
    return arr.map(feature => {
      return (
        <li className="flex justify-start items-center h-40">
          <div>{_icon}</div>
          <div className="bg-transparent w-8" />
          <p className="text-text1 text-sm">{feature}</p>
        </li>
      );
    });
  };

  return (
    <div className="flex flex-col rounded bg-accent1_15 h-400">
      <div className="w-full h-80 p-20">
        <div className="flex justify-between">
          <div className="flex-1 h-full">
            <h3 className="text-accent2 text-md3 tracking-tight_sm pb-4 w-6/10">
              {title}
            </h3>
          </div>
          <div>
            <div className="h-30">
              <img src={logo} className="w-auto h-full" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1 w-full px-20 pt-10 pb-20">
        <ul>{_featuresList(features)}</ul>
      </div>
    </div>
  );
};
ComparisonCard.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  mode: PropTypes.oneOf(["positive", "negative"]),
};
ComparisonCard.defaultProps = {
  mode: "positive",
};

/**
 * ComparisonSection
 */
const ComparisonSection = props => {
  const intl = useIntl();

  const _sectionTitle = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.sectionTitle.title",
  });
  const _subTitle = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.sectionTitle.subTitle",
  });
  const _text = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.text",
  });

  const _smartQATitle = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.smartQA.title",
  });
  const _smartQAFeature1 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.smartQA.features.feature1",
  });
  const _smartQAFeature2 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.smartQA.features.feature2",
  });
  const _smartQAFeature3 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.smartQA.features.feature3",
  });
  const _smartQAFeature4 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.smartQA.features.feature4",
  });
  const _smartQAFeature5 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.smartQA.features.feature5",
  });
  const _smartQAFeature6 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.smartQA.features.feature6",
  });

  const _competitorsTitle = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.competitors.title",
  });
  const _competitorsFeature1 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.competitors.features.feature1",
  });
  const _competitorsFeature2 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.competitors.features.feature2",
  });
  const _competitorsFeature3 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.competitors.features.feature3",
  });
  const _competitorsFeature4 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.competitors.features.feature4",
  });
  const _competitorsFeature5 = intl.formatMessage({
    id: "content.mainLanding.comparisonSection.competitors.features.feature5",
  });

  return (
    <section id="comparison" className={cx("bg-bg2", theme.spacing.sectionY)}>
      <Wrapper>
        <div className="flex flex-col md:flex-row">
          <div className="w-auto md:w-5/12 pr-40">
            <SectionHeading
              mainText={_sectionTitle}
              subText={_subTitle}
              textColor={theme.colors.accent1}
              subTextColor={theme.colors.accent2_80}
              lineColor={theme.colors.accent2}
              alignment="left"
            />

            <div className={cx("bg-transparent", theme.spacing.blockY)} />

            <p className="text-md3 lg:text-md2 text-text3 leading-relaxed">
              {_text}
            </p>
          </div>

          <div className="w-auto md:w-7/12">
            <div className="bg-transparent h-40 md:h-200" />

            <div className="grid grid-cols-1 lg:grid-cols-2 grid-gap-20 lg:grid-gap-40">
              <ComparisonCard
                title={_smartQATitle}
                logo={logoInCloudColor}
                features={[
                  _smartQAFeature1,
                  _smartQAFeature2,
                  _smartQAFeature3,
                  _smartQAFeature4,
                  _smartQAFeature5,
                  _smartQAFeature6,
                ]}
              />

              <ComparisonCard
                title={_competitorsTitle}
                features={[
                  _competitorsFeature1,
                  _competitorsFeature2,
                  _competitorsFeature3,
                  _competitorsFeature4,
                  _competitorsFeature5,
                ]}
                mode="negative"
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
ComparisonSection.propTypes = {};
ComparisonSection.defaultProps = {};

export default ComparisonSection;
