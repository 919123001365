import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, Button, IconFrame, SectionHeading } from "../core";
import { IconDatabase, IconCloud } from "../../assets/@feathericons";

/**
 * SecurityCard
 */
const SecurityCard = ({ title, text, icon, iconSize, iconColor }) => {
  return (
    <div className="flex flex-col justify-between bg-accent1_15 rounded h-400">
      <div className="px-20 pt-20">
        <IconFrame
          icon={icon}
          iconSize={iconSize}
          iconColor={theme.colors.accent1}
          iconStrokeWidth={1.5}
          frameColor={theme.colors.bg1}
        />
      </div>

      <div className="flex-1 px-40 pb-60">
        <div className="flex flex-col justify-end h-full">
          <h2 className="text-accent2 text-md2 tracking-tight_sm pb-20">
            {title}
          </h2>
          <p className="text-text3 text-md3 leading-normal">{text}</p>
        </div>
      </div>
    </div>
  );
};
SecurityCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
};
SecurityCard.defaultProps = {};

/**
 * SecuritySection
 */
const SecuritySection = props => {
  const iconDatabase = <IconDatabase />;
  const iconCloud = <IconCloud />;

  const intl = useIntl();

  const _sectionTitle = intl.formatMessage({
    id: "content.mainLanding.securitySection.sectionTitle.title",
  });
  const _subTitle = intl.formatMessage({
    id: "content.mainLanding.securitySection.sectionTitle.subTitle",
  });

  const _block1Title = intl.formatMessage({
    id: "content.mainLanding.securitySection.block1.title",
  });
  const _block1Text = intl.formatMessage({
    id: "content.mainLanding.securitySection.block1.text",
  });

  const _block2Title = intl.formatMessage({
    id: "content.mainLanding.securitySection.block2.title",
  });
  const _block2Text = intl.formatMessage({
    id: "content.mainLanding.securitySection.block2.text",
  });

  return (
    <section id="security" className={cx("bg-bg3", theme.spacing.sectionY)}>
      <Wrapper>
        <div className="flex flex-col justify-center items-center">
          <SectionHeading
            mainText={_sectionTitle}
            subText={_subTitle}
            textColor={theme.colors.accent1}
            subTextColor={theme.colors.accent2_80}
            lineColor={theme.colors.accent2}
          />

          <div className={cx("bg-transparent", theme.spacing.blockY)} />

          <div className="grid grid-cols-1 md:grid-cols-2 grid-gap-20 lg:grid-gap-40">
            <SecurityCard
              title={_block1Title}
              text={_block1Text}
              icon={iconDatabase}
              iconSize={32}
            />
            <SecurityCard
              title={_block2Title}
              text={_block2Text}
              icon={iconCloud}
              iconSize={32}
            />
          </div>

          <div className={cx("bg-transparent", theme.spacing.blockY)} />

          <Button
            to={"/hello"}
            label={`Learn more about our security`}
            labelColor={theme.colors.accent2}
            frameColor={theme.colors.accent2_25}
          />
        </div>
      </Wrapper>
    </section>
  );
};
SecuritySection.propTypes = {};
SecuritySection.defaultProps = {};

export default SecuritySection;
