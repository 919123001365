import enSmartQAAppProjectDetailFull from "./product/en-smartqa-app-project-detail-full@2x.png";
import jaSmartQAAppProjectDetailFull from "./product/ja-smartqa-app-project-detail-full@2x.png";
import enSmartQAAppProjectsOverviewFull from "./product/en-smartqa-app-projects-overview-full@2x.png";
import jaSmartQAAppProjectsOverviewFull from "./product/ja-smartqa-app-projects-overview-full@2x.png";
import enSmartQAAppUploadsFull from "./product/en-smartqa-app-uploads-full@2x.png";
import jaSmartQAAppUploadsFull from "./product/ja-smartqa-app-uploads-full@2x.png";

import enSmartQAAppProjectDetailCrop from "./product/en-smartqa-app-project-detail-crop@2x.png";
import jaSmartQAAppProjectDetailCrop from "./product/ja-smartqa-app-project-detail-crop@2x.png";
import jaSmartQAAppProjectDetailCropNew from "./product/ja-smartqa-app-project-detail-crop-new@2x.png";
import enSmartQAAppProjectsOverviewCrop from "./product/en-smartqa-app-projects-overview-crop@2x.png";
import jaSmartQAAppProjectsOverviewCrop from "./product/ja-smartqa-app-projects-overview-crop@2x.png";
import enSmartQAAppUploadsCrop from "./product/en-smartqa-app-uploads-crop@2x.png";
import jaSmartQAAppUploadsCrop from "./product/ja-smartqa-app-uploads-crop@2x.png";
import jaSmartQAAppUploadsCropNew from "./product/ja-smartqa-app-uploads-crop-new@2x.png";
import jaMainOne from "./product/jp-hero-one.png";
import jaMainTwo from "./product/jp-hero-two.png";
import jaMainThree from "./product/jp-hero-three.png";

export {
  enSmartQAAppProjectDetailFull,
  jaSmartQAAppProjectDetailFull,
  enSmartQAAppProjectsOverviewFull,
  jaSmartQAAppProjectsOverviewFull,
  enSmartQAAppUploadsFull,
  jaSmartQAAppUploadsFull,
  enSmartQAAppProjectDetailCrop,
  jaSmartQAAppProjectDetailCrop,
  jaSmartQAAppProjectDetailCropNew,
  enSmartQAAppProjectsOverviewCrop,
  jaSmartQAAppProjectsOverviewCrop,
  enSmartQAAppUploadsCrop,
  jaSmartQAAppUploadsCrop,
  jaSmartQAAppUploadsCropNew,
  jaMainOne,
  jaMainTwo,
  jaMainThree,
};
