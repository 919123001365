import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, Button, SectionHeading } from "../core";
import { GlyphIconCheck } from "../../assets/@chipicons";
import { document, window } from "browser-monads";

/**
 * PlanCard
 */
const PlanCard = ({
  title,
  subTitle,
  features,
  currency,
  pricePerYear,
  periodLabel,
  pricePerMonth,
  buttonLabel,
  buttonUrl,
  cardBgColor,
  buttonLabelColor,
  buttonFrameColor,
  hasCardBorder,
  isHidePrice,
  isHovering,
}) => {
  const _priceStyle = isHidePrice ? cx("hidden") : cx("block");
  const [cardHovering, setCardHovering] = useState(false);
  const [_cardStyle, setCardStyle] = useState(cx("border-0"));
  const [_bgColor, setBgColor] = useState(cardBgColor);

  useEffect(() => {
    console.log(title, cardHovering, ", global hovering", isHovering);
    setCardStyle(
      (isHovering && cardHovering) || (!isHovering && hasCardBorder)
        ? cx("border-2 border-solid border-primary")
        : cx("border-0")
    );

    setBgColor(
      isHovering
        ? cardHovering
          ? theme.colors.bg1
          : theme.colors.accent1_15
        : cardBgColor
    );
  }, [isHovering, cardHovering, hasCardBorder]);

  const _featuresList = arr => {
    return arr.map(feature => {
      return (
        <li className="flex py-8">
          <GlyphIconCheck
            color={theme.colors.statusPositive}
            className="check-svg"
          />
          <div className="w-8 bg-transparent" />
          <p className="text-text1 text-sm2">{feature}</p>
        </li>
      );
    });
  };

  return (
    <div
      style={{ backgroundColor: _bgColor }}
      className={cx(_cardStyle, "flex flex-col rounded w-full lg:w-300 h-560")}
      onMouseEnter={() => setCardHovering(true)}
      onMouseLeave={() => setCardHovering(false)}>
      <div className="w-full p-20">
        <h3 className="text-accent2 text-md2 tracking-tight_sm text-center pb-4">
          {title}
        </h3>
        <p className="text-accent1 text-sm text-center leading-normal">
          {subTitle}
        </p>
      </div>

      <div className="flex-1 w-full px-20 pt-10 pb-20">
        <ul>{_featuresList(features)}</ul>
      </div>

      <div
        className={cx(
          _priceStyle,
          "flex flex-col justify-center items-center w-full p-20"
        )}>
        <div className="flex mb-6">
          <p className="text-accent1 text-md3">{currency}</p>
          <div className="w-4 bg-transparent" />
          <p className="font-medium text-text1 text-md3">{pricePerYear}</p>
        </div>

        <p className="font-medium text-accent2_80 text-sm3 text-center uppercase mb-6">
          {periodLabel}
        </p>

        <p className="text-text3 text-sm3 text-center">{pricePerMonth}</p>
      </div>

      <div className="flex justify-center items-center w-full p-20">
        <Button
          mode="href"
          href={buttonUrl}
          label={buttonLabel}
          labelColor={theme.colors.altText1}
          frameColor={theme.colors.accent2}
          isStretch="true"
        />
      </div>
    </div>
  );
};
PlanCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  currency: PropTypes.string,
  pricePerYear: PropTypes.string,
  periodLabel: PropTypes.string,
  pricePerMonth: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  cardBgColor: PropTypes.string,
  buttonLabelColor: PropTypes.string,
  buttonFrameColor: PropTypes.string,
  hasCardBorder: PropTypes.bool,
  isHidePrice: PropTypes.bool,
};
PlanCard.defaultProps = {
  cardBgColor: "white",
  buttonLabelColor: "white",
  buttonFrameColor: "black",
  hasCardBorder: false,
  isHidePrice: false,
};

/**
 * PricingSection
 */
const PricingSection = ({ siteProps }) => {
  const intl = useIntl();

  const _sectionTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.sectionTitle.title",
  });
  const _subTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.sectionTitle.subTitle",
  });

  const _benefit1 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.benefits.benefit1",
  });
  const _benefit2 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.benefits.benefit2",
  });
  const _benefit3 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.benefits.benefit3",
  });

  const _planFreeTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planFree.title",
  });
  const _planFreeSubTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planFree.subTitle",
  });
  const _planFreeFeature1 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planFree.features.feature1",
  });
  const _planFreeFeature2 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planFree.features.feature2",
  });
  const _planFreeFeature3 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planFree.features.feature3",
  });
  const _planFreeButtonLabel = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planFree.buttonLabel",
  });

  const _planBasicTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.title",
  });
  const _planBasicSubTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.subTitle",
  });
  const _planBasicFeature1 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.features.feature1",
  });
  const _planBasicFeature2 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.features.feature2",
  });
  const _planBasicFeature3 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.features.feature3",
  });
  const _planBasicCurrency = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.price.currency",
  });
  const _planBasicPricePerYear = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.price.pricePerYear",
  });
  const _planBasicPeriodLabel = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.price.periodLabel",
  });
  const _planBasicPricePerMonth = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.price.pricePerMonth",
  });
  const _planBasicButtonLabel = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planBasic.buttonLabel",
  });

  const _planProTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.title",
  });
  const _planProSubTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.subTitle",
  });
  const _planProFeature1 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.features.feature1",
  });
  const _planProFeature2 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.features.feature2",
  });
  const _planProFeature3 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.features.feature3",
  });
  const _planProFeature4 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.features.feature4",
  });
  // const _planProFeature5 = intl.formatMessage({
  //   id: "content.mainLanding.pricingSection.planPro.features.feature5",
  // });
  const _planProCurrency = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.price.currency",
  });
  const _planProPricePerYear = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.price.pricePerYear",
  });
  const _planProPeriodLabel = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.price.periodLabel",
  });
  const _planProPricePerMonth = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.price.pricePerMonth",
  });
  const _planProButtonLabel = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planPro.buttonLabel",
  });

  const _planMasterTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.title",
  });

  const _planMasterSubTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.subTitle",
  });
  const _planMasterFeature1 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.features.feature1",
  });
  const _planMasterFeature2 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.features.feature2",
  });
  const _planMasterFeature3 = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.features.feature3",
  });

  const _newFeature =
    intl.locale === "en"
      ? "200gb storage for file uploads"
      : "ファイルアップロード用の200GBストレージ"; // this needs to be replace with intl messages

  // const _planMasterFeature4 = intl.formatMessage({
  //   id: "content.mainLanding.pricingSection.planMaster.features.feature4",
  // });
  // const _planMasterFeature5 = intl.formatMessage({
  //   id: "content.mainLanding.pricingSection.planMaster.features.feature5",
  // });
  const _planMasterCurrency = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.price.currency",
  });
  const _planMasterPricePerYear = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.price.pricePerYear",
  });
  const _planMasterPeriodLabel = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.price.periodLabel",
  });
  // const _planMasterPricePerMonth = intl.formatMessage({
  //   id: "content.mainLanding.pricingSection.planMaster.price.pricePerMonth",
  // });
  const _planMasterButtonLabel = intl.formatMessage({
    id: "content.mainLanding.pricingSection.planMaster.buttonLabel",
  });

  const _supportBlockTitle = intl.formatMessage({
    id: "content.mainLanding.pricingSection.supportBlock.title",
  });
  const _supportBlockText = intl.formatMessage({
    id: "content.mainLanding.pricingSection.supportBlock.text",
  });
  const _supportBlockButtonLabel = intl.formatMessage({
    id: "content.mainLanding.pricingSection.supportBlock.buttonLabel",
  });

  const openContact = () => {
    const url = new URL(window.location.href);
    const base = ["mail", "to", ":", "contact"];
    const aTag = document.createElement("a");
    aTag.href =
      base.join("") +
      "@" +
      url.hostname.replace("www.", "").replace("WWW.", "");
    aTag.click();
  };

  const [hovering, setHovering] = useState(false);

  return (
    <section id="pricing" className={cx("bg-colorBg2", theme.spacing.sectionY)}>
      <Wrapper>
        <div className="flex flex-col justify-center items-center">
          <SectionHeading
            mainText={_sectionTitle}
            subText={_subTitle}
            textColor={theme.colors.accent1}
            subTextColor={theme.colors.accent2_80}
            lineColor={theme.colors.accent2}
          />

          <div className={cx("bg-transparent", theme.spacing.blockYTight)} />

          <div className="hidden flex flex-col lg:flex-row items-start">
            <div className="flex items-center py-4 mr-10">
              <div className="block lg:hidden w-8 h-8 bg-accent1 rounded-full mr-10"></div>
              <p className="text-md3 text-text3 leading-normal">{_benefit1}</p>
            </div>

            <div className="flex items-center py-4 mr-10">
              <div className="w-8 h-8 bg-accent1 rounded-full mr-10"></div>
              <p className="text-md3 text-text3 leading-normal">{_benefit2}</p>
            </div>

            <div className="flex items-center py-4">
              <div className="w-8 h-8 bg-accent1 rounded-full mr-10"></div>
              <p className="text-md3 text-text3 leading-normal">{_benefit3}</p>
            </div>
          </div>

          <div className={cx("bg-transparent", theme.spacing.blockYTight)} />

          <div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 grid-gap-20 w-full lg:w-auto price-grid-container"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}>
            <PlanCard
              title={_planFreeTitle}
              subTitle={_planFreeSubTitle}
              features={[
                _planFreeFeature1,
                _planFreeFeature2,
                _planFreeFeature3,
              ]}
              buttonLabel={_planFreeButtonLabel}
              buttonUrl={siteProps.appRegisterUrl}
              cardBgColor={theme.colors.accent1_15}
              isHidePrice={true}
              isHovering={hovering}
            />
            <PlanCard
              title={_planBasicTitle}
              subTitle={_planBasicSubTitle}
              features={[
                _planBasicFeature1,
                _planBasicFeature2,
                _planBasicFeature3,
              ]}
              currency={_planBasicCurrency}
              pricePerYear={_planBasicPricePerYear}
              periodLabel={_planBasicPeriodLabel}
              pricePerMonth={_planBasicPricePerMonth}
              buttonLabel={_planBasicButtonLabel}
              buttonUrl={siteProps.appRegisterUrl}
              cardBgColor={theme.colors.accent1_15}
              isHovering={hovering}
            />
            <PlanCard
              title={_planProTitle}
              subTitle={_planProSubTitle}
              features={[
                _planProFeature1,
                _planProFeature2,
                _planProFeature3,
                _planProFeature4,
                // _planProFeature5,
              ]}
              currency={_planProCurrency}
              pricePerYear={_planProPricePerYear}
              periodLabel={_planProPeriodLabel}
              pricePerMonth={_planProPricePerMonth}
              buttonLabel={_planProButtonLabel}
              buttonUrl={siteProps.appRegisterUrl}
              cardBgColor={theme.colors.bg1}
              hasCardBorder={true}
              isHovering={hovering}
            />
            <PlanCard
              title={_planMasterTitle}
              subTitle={_planMasterSubTitle}
              features={[
                _planMasterFeature1,
                _newFeature,
                _planMasterFeature2,
                _planMasterFeature3,
                // _planMasterFeature4,
                // _planMasterFeature5,
              ]}
              currency={_planMasterCurrency}
              pricePerYear={_planMasterPricePerYear}
              periodLabel={_planMasterPeriodLabel}
              // pricePerMonth={_planMasterPricePerMonth}
              buttonLabel={_planMasterButtonLabel}
              buttonUrl={siteProps.appRegisterUrl}
              cardBgColor={theme.colors.accent1_15}
              isHovering={hovering}
            />
          </div>

          <div className={cx("bg-transparent", theme.spacing.blockYLoose)} />

          <div className="flex flex-col items-center">
            <h3 className="text-accent2 text-md tracking-tight_sm text-center pb-12">
              {_supportBlockTitle}
            </h3>
            <p className="text-md3 lg:text-md2 text-text3 text-center leading-normal pb-30">
              {_supportBlockText}
            </p>
            <Button
              mode="button"
              // href={`mailto:${siteProps.companyMailToUrl}`}
              label={_supportBlockButtonLabel}
              labelColor={theme.colors.accent2}
              frameColor={theme.colors.accent2_25}
              onClick={() => openContact()}
            />
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
PricingSection.propTypes = {};
PricingSection.defaultProps = {};

export default PricingSection;
