import React from "react";
import PropTypes from "prop-types";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  HeroSection,
  WhySection,
  ComparisonSection,
  PricingSection,
} from "../components/pageMainLanding";
import { FeaturesSection, CTASection } from "../components/pageShared";
import {
  enSmartQAAppProjectsOverviewCrop,
  jaSmartQAAppProjectsOverviewCrop,
  jaSmartQAAppProjectDetailCropNew,
  jaSmartQAAppUploadsCropNew,
} from "../assetsContent";

/**
 * IndexPage
 */
const IndexPage = ({ data }) => {
  const {
    appLoginUrl,
    appRegisterUrl,
    companyMailToUrl,
  } = data.site.siteMetadata;

  const intl = useIntl();

  // const _sectionTitle = intl.formatMessage({
  //   id: "content.mainLanding.featuresSection.sectionTitle.title",
  // });
  const _sectionTitle = intl.locale === "en" ? "Our product" : "主な機能の紹介"; // this needs to be replace with intl messages

  const _subTitle = intl.formatMessage({
    id: "content.mainLanding.featuresSection.sectionTitle.subTitle",
  });

  const _feature1Title = intl.formatMessage({
    id: "content.mainLanding.featuresSection.block1.title",
  });
  const _feature1Text = intl.formatMessage({
    id: "content.mainLanding.featuresSection.block1.text",
  });
  const _feature1ButtonLabel = intl.formatMessage({
    id: "content.mainLanding.featuresSection.block1.buttonLabel",
  });

  const _feature2Title = intl.formatMessage({
    id: "content.mainLanding.featuresSection.block2.title",
  });
  const _feature2Text = intl.formatMessage({
    id: "content.mainLanding.featuresSection.block2.text",
  });
  const _feature2ButtonLabel = intl.formatMessage({
    id: "content.mainLanding.featuresSection.block2.buttonLabel",
  });

  // const _feature3Title = intl.formatMessage({
  //   id: "content.mainLanding.featuresSection.block3.title",
  // });
  // const _feature3Text = intl.formatMessage({
  //   id: "content.mainLanding.featuresSection.block3.text",
  // });

  // this needs to be replace with intl messages
  const _feature3Title =
    intl.locale === "en" ? "Calendar and Kanban" : "カレンダーとカンバン";
  const _feature3Text =
    intl.locale === "en"
      ? "QA sheets have a calendar and Kanban view, so it's easy to track QA using the sheet screen, but it's also very useful to easily project manage tasks with various shared members."
      : "QAシートにはカレンダーとカンバンビューがあるため、シート画面を使用してQAを追跡するのは簡単ですが、さまざまな共有メンバーとのタスクを簡単にプロジェクト管理することも非常に便利です。";

  const _feature3ButtonLabel = intl.formatMessage({
    id: "content.mainLanding.featuresSection.block3.buttonLabel",
  });

  return (
    <IntlContextConsumer>
      {({ language }) => (
        <Layout>
          <SEO title="Home" />
          <HeroSection siteProps={{ appLoginUrl, appRegisterUrl }} />
          <WhySection />
          <FeaturesSection
            sectionId="features"
            sectionTitle={_sectionTitle}
            block1Title={_feature1Title}
            block1Text={_feature1Text}
            block1Image={
              language === "en"
                ? enSmartQAAppProjectsOverviewCrop
                : jaSmartQAAppProjectsOverviewCrop
            }
            block1ButtonLabel={_feature1ButtonLabel}
            block2Title={_feature2Title}
            block2Text={_feature2Text}
            block2Image={
              language === "en"
                ? jaSmartQAAppProjectDetailCropNew
                : jaSmartQAAppProjectDetailCropNew
            }
            block2ButtonLabel={_feature2ButtonLabel}
            block3Title={_feature3Title}
            block3Text={_feature3Text}
            block3Image={
              language === "en"
                ? jaSmartQAAppUploadsCropNew
                : jaSmartQAAppUploadsCropNew
            }
            block3ButtonLabel={_feature3ButtonLabel}
          />
          <ComparisonSection />
          <PricingSection
            siteProps={{ appLoginUrl, appRegisterUrl, companyMailToUrl }}
          />
          <CTASection siteProps={{ appLoginUrl, appRegisterUrl }} />
        </Layout>
      )}
    </IntlContextConsumer>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        appLoginUrl
        appRegisterUrl
        companyMailToUrl
      }
    }
  }
`;

export default IndexPage;
