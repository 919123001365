import React from "react";
import PropTypes from "prop-types";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, Button } from "../core";
import { topDiagonalBg } from "../../assets/graphics";
import {
  enSmartQAAppProjectDetailFull,
  jaSmartQAAppProjectDetailFull,
  jaMainOne,
  jaMainTwo,
  jaMainThree,
} from "../../assetsContent";

import Slider from "react-slick";

/**
 * HeroSection
 */
const HeroSection = ({ siteProps }) => {
  const intl = useIntl();
  const _tagline = intl.formatMessage({
    id: "content.mainLanding.heroSection.tagline",
  });
  const _text = intl.formatMessage({
    id: "content.mainLanding.heroSection.text",
  });
  const _registerEmailInputText = intl.formatMessage({
    id: "content.shared.forms.register.emailInputText",
  });
  const _registerButtonLabel = intl.formatMessage({
    id: "content.shared.forms.register.buttonLabel",
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  return (
    <IntlContextConsumer>
      {({ language }) => (
        <section
          id="home"
          style={{
            backgroundImage: `url(${topDiagonalBg})`,
            backgroundPosition: "bottom left",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
          className={cx(
            "bg-colorBg2",
            theme.spacing.sectionYTight,
            "sm-pb-50"
          )}>
          <Wrapper>
            <div className="flex flex-col items-center">
              <div className="flex flex-col items-center">
                <h1 className="font-medium text-md md:text-lg4 lg:text-lg3 tracking-tight_lg leading-normal lg:leading-none text-center text-accent1 w-auto md:w-9/12 lg:w-7/12 mb-16">
                  {_tagline}
                </h1>
                <p className="text-md3 leading-normal text-center max-w-500">
                  {_text}
                </p>
              </div>

              <div className={cx("bg-transparent", theme.spacing.blockY)} />

              <div className="flex flex-col bg-accent1_15 p-16 rounded">
                <input
                  className="text-md4 rounded w-300 md:w-400 h-56 px-10 mb-8 outline-none"
                  type="text"
                  name="email"
                  placeholder={_registerEmailInputText}
                />
                <Button
                  mode="href"
                  href={siteProps.appRegisterUrl}
                  label={_registerButtonLabel}
                  roundness="round"
                  labelColor={theme.colors.altText1}
                  frameColor={theme.colors.accent2}
                  size="lg"
                />
              </div>

              <div className={cx("bg-transparent", theme.spacing.blockY)} />

              {/* <div className="w-auto lg:w-10/12">
                <img
                  style={{
                    border: "2px",
                    borderStyle: "solid",
                    borderColor: theme.colors.accent1_15,
                  }}
                  className="max-w-full max-h-full rounded"
                  src={
                    language === "en"
                      ? enSmartQAAppProjectDetailFull
                      : jaSmartQAAppProjectDetailFull
                  }
                  alt={`smartQA`}
                />
              </div> */}

              <div className="w-auto lg:w-10/12 md:w-full sm-w-full">
                <Slider {...settings}>
                  <div className="w-full lg:w-10/12">
                    <img
                      style={{
                        border: "2px",
                        borderStyle: "solid",
                        borderColor: theme.colors.accent1_15,
                      }}
                      className="max-w-full max-h-full rounded"
                      src={jaMainOne}
                      alt={`smartQA`}
                    />
                  </div>
                  <div className="w-full lg:w-10/12">
                    <img
                      style={{
                        border: "2px",
                        borderStyle: "solid",
                        borderColor: theme.colors.accent1_15,
                      }}
                      className="max-w-full max-h-full rounded"
                      src={jaMainTwo}
                      alt={`smartQA`}
                    />
                  </div>
                  <div className="w-full lg:w-10/12">
                    <img
                      style={{
                        border: "2px",
                        borderStyle: "solid",
                        borderColor: theme.colors.accent1_15,
                      }}
                      className="max-w-full max-h-full rounded"
                      src={jaMainThree}
                      alt={`smartQA`}
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </Wrapper>
        </section>
      )}
    </IntlContextConsumer>
  );
};
HeroSection.propTypes = {};
HeroSection.defaultProps = {};

export default HeroSection;
