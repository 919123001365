import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "gatsby-plugin-intl";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, IconFrame, SectionHeading } from "../core";
import { IconDisc, IconLock, IconMessage } from "../../assets/@feathericons";

/**
 * USPCard
 */
const USPCard = ({ title, text, icon, iconSize, iconColor }) => {
  return (
    <div className="flex flex-col items-center bg-bg1 rounded h-300 py-40">
      <IconFrame
        icon={icon}
        iconSize={iconSize}
        iconColor={theme.colors.accent1}
        iconStrokeWidth={1.5}
        frameColor={theme.colors.accent1_25}
      />

      <div className="bg-transparent h-20" />

      <div className="max-w-320 px-20">
        <h2 className="text-accent2 text-md2 tracking-tight_sm text-center pb-12">
          {title}
        </h2>
        <p className="text-text3 text-md4 text-center leading-normal">{text}</p>
      </div>
    </div>
  );
};
USPCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.isRequired,
  icon: PropTypes.element,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
};
USPCard.defaultProps = {};

/**
 * WhySection
 */
const WhySection = props => {
  const iconDisc = <IconDisc />;
  const iconLock = <IconLock />;
  const iconMessage = <IconMessage />;

  const intl = useIntl();

  const _sectionTitle = intl.formatMessage({
    id: "content.mainLanding.whySection.sectionTitle.title",
  });
  const _block1Title = intl.formatMessage({
    id: "content.mainLanding.whySection.block1.title",
  });
  const _block1Text = intl.formatMessage({
    id: "content.mainLanding.whySection.block1.text",
  });
  const _block2Title = intl.formatMessage({
    id: "content.mainLanding.whySection.block2.title",
  });
  const _block2Text = intl.formatMessage({
    id: "content.mainLanding.whySection.block2.text",
  });
  const _block3Title = intl.formatMessage({
    id: "content.mainLanding.whySection.block3.title",
  });
  const _block3Text = intl.formatMessage({
    id: "content.mainLanding.whySection.block3.text",
  });

  return (
    <section className={cx("bg-colorBg1", "pt-20 md:pt-40 pb-20 md:pb-40")}>
      <Wrapper>
        <SectionHeading
          mainText={_sectionTitle}
          size={"sm"}
          textColor={theme.colors.altText1}
          subTextColor={theme.colors.altText1_60}
          lineColor={theme.colors.accent2}
          spacingB={40}
        />

        <div className="bg-transparent h-40" />

        <div className="grid grid-cols-1 lg:grid-col-3 grid-gap-20 lg:grid-gap-40">
          <div className="flex flex-col">
            <div className="bg-transparent h-auto lg:h-40" />
            <USPCard
              title={_block1Title}
              text={_block1Text}
              icon={iconDisc}
              iconSize={32}
            />
          </div>

          <div className="flex flex-col">
            <USPCard
              title={_block2Title}
              text={_block2Text}
              icon={iconLock}
              iconSize={32}
            />
          </div>

          <div className="flex flex-col">
            <div className="bg-transparent h-auto lg:h-40" />
            <USPCard
              title={_block3Title}
              text={_block3Text}
              icon={iconMessage}
              iconSize={32}
            />
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
WhySection.propTypes = {};
WhySection.defaultProps = {};

export default WhySection;
